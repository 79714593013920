import React from 'react';

import loadable from '@loadable/component';
import classNames from 'classnames';

import styles from './ModuleBuilder.css';
import { Section } from '../../../models/Strapi';
import ExclusiveGamesModule2025 from '../ExclusiveGamesModule2025/ExclusiveGamesModule2025';
import GamingExperienceModule2025 from '../GamingExperienceModule2025/GamingExperienceModule2025';
import MainModule2025 from '../MainModule2025/MainModule2025';
import OurMembersModule2025 from '../OurMembersModule2025/OurMembersModule2025';
import PricingTariffsModule2025 from '../PricingTariffsModule2025/PricingTariffsModule';

const MainModule = loadable(() => import('../MainModule/MainModule'));
const ColumnsModule = loadable(() => import('../ColumnsModule/ColumnsModule'));
const AccordionModule = loadable(() => import('../AccordionModule/AccordionModule'));
const GameInfoModule = loadable(() => import('../GameInfoModule/GameInfoModule'));
const InfoModule = loadable(() => import('../InfoModule/InfoModule'));
const ImageListModule = loadable(() => import('../ImageListModule/ImageListModule'));
const LinkListModule = loadable(() => import('../LinkListModule/LinkListModule'));
const ComingSoonGameModule = loadable(() => import('../ComingSoonGameModule/ComingSoonGameModule'));
const AccordionModule2025 = loadable(() => import('../AccordionModule2025/AccordionModule2025'));
// Constructs the Strapi modules
const ModuleBuilder = React.memo((props: { section: Section<unknown> }) => {
  const { section } = props;
  let result;

  switch (section.uid) {
    case 'MainModule':
      result = <MainModule {...section} />;
      break;
    case 'MainModule2025':
      result = <MainModule2025 {...section} />;
      break;
    case 'Columns':
      result = <ColumnsModule {...section} />;
      break;
    case 'AccordionModule':
      result = <AccordionModule {...section} />;
      break;
    case 'GameInfoModule':
      result = <GameInfoModule {...section} />;
      break;
    case 'InfoModule':
      result = <InfoModule {...section} />;
      break;
    case 'LinkListModule':
      result = <LinkListModule {...section} />;
      break;
    case 'ImageListModule':
      result = <ImageListModule {...section} />;
      break;
    case 'ComingSoonGameModule':
      result = <ComingSoonGameModule {...section} />;
      break;
    case 'GamingExperienceModule2025':
      result = <GamingExperienceModule2025 {...section} />;
      break;
    case 'PricingTariffsModule2025':
      result = <PricingTariffsModule2025 {...section} />;
      break;
    case 'ExclusiveGamesModule2025':
      result = <ExclusiveGamesModule2025 {...section} />;
      break;
    case 'OurMembersModule2025':
      result = <OurMembersModule2025 {...section} />;
      break;
    case 'AccordionModule2025':
      result = <AccordionModule2025 {...section} />;
      break;
    default:
      return <></>;
  }

  return (
    <div className={classNames(styles.container)}>
      {result}
    </div>
  );
});

export default ModuleBuilder;
