import React, { ReactElement, useEffect, CSSProperties } from "react";

import classNames from 'classnames';
import { push } from 'connected-react-router';
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from 'react-redux';

import styles from './PricingTariffsModule.css';
import { I18nText } from "../../../atoms/i18nText/i18nText";
import { NavLink } from "../../../atoms/Link/Link";
import { environment } from '../../../config/environment';
import { PaymentType, RecurlyPlans } from "../../../constants/RecurlyPurchase";
import {
  checkTrialPeriodAvailability,
  getPlanByCode,
  getPlanNameByCode,
  SUBSCRIPTION_PLAN_NAME,
  SubscriptionPlans
} from "../../../constants/SubscriptionPlan";
import { Media } from '../../../models/Strapi';
import { AppInsightService } from '../../../services/AppInsight';
import { Media as MediaResponsive } from '../../../services/MediaService';
import PaymentService from '../../../services/PaymentService';
import { UrlService } from '../../../services/UrlService';
import UserService from '../../../services/UserService';
import { setActiveSubscriptionPlan } from '../../../store/ducks/subscription/common';
import { setHostedLoginToken } from '../../../store/ducks/user';
import {
  getSubscriptionPlans
} from "../../../templates/Profile/Tabs/PaymentAndSubscription/PlusPaymentAndSubscription/components/PlusJoinBlock/PlusJoinBlock";
import { Carousel } from '../../Carousel/Carousel';

interface IPricingTariffsModule {
  title: string;
  subTitle: string;
  background?: Media;
  backgroundMobile?: Media;
  backgroundFallback: string;
  component: any;
}

interface ITariffsPlanComponent {
  __component: string;
  id: number;
  note: string;
  tariffPlans: ITariffPlan[];
}

interface ITariffPlan {
  id: number;
  periodTitle: string;
  periodSubtitle: string;
  planName: string;
  priceTitle: string;
  priceSubtitle: string;
  benefits: IBenefit[];
  isPromotionalPlan: boolean;
  CTA: ICTAButtonComponent;
}

interface IBenefit {
  id: number;
  text: string;
}

interface ICTAButtonComponent {
  __component: string;
  id: number;
  action: string;
  backgroundColor: string;
  gradientColor1: string | null;
  gradientColor2: string | null;
  gradientColor3: string | null;
  isPromotionalPlan: boolean | null;
  label: string;
  textColor: string;
  url: string;
}

const PricingTariffsModule2025 = React.memo((props: IPricingTariffsModule) => {
  const {
    title,
    subTitle,
    background,
    backgroundMobile,
    backgroundFallback,
    component: [PricingTariffsModule]
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeUserSubscriptions = useSelector(({ activeUserSubscriptions }) => activeUserSubscriptions);
  const hostedLoginToken = useSelector(({ hostedLoginToken }) => hostedLoginToken);
  const plans = useSelector(({ subscriptionPlans }) => subscriptionPlans);
  const userExpiredSubscriptions = useSelector(({ expiredUserSubscriptions }) => expiredUserSubscriptions);
  const plusFeatureFlag = useSelector(({ arkConfig }) => arkConfig?.plusFeature?.isEnabled);

  useEffect(() => {
    if (activeUserSubscriptions?.length > 0 && !hostedLoginToken) {
      void fetchRecurlyToken();
    }
  }, [hostedLoginToken, activeUserSubscriptions]);

  useEffect(() => {
    if (!plans) {
      getSubscriptionPlans(dispatch, null);
    }
  }, [plans]);

  const fetchRecurlyToken = async () => {
    try {
      const res = await PaymentService.getRecurlyData();

      dispatch(setHostedLoginToken(res.hostedLoginToken));
    } catch (err) {
      console.log('PaymentService.getRecurlyData()', err);
      AppInsightService.trackAppError(err, { data: 'getRecurlyData()' });
    }
  };
  const getBackground = (background: Media, backgroundFallback: string): CSSProperties => {
    const result = {} as React.CSSProperties;

    if (background) {
      result.backgroundImage = `url(${UrlService.createStrapiMediaUrl(background.url)})`;
    }

    result.backgroundColor = backgroundFallback;
    return result;
  };
  const onSubscriptionButtonClick = (planType: string): void => {
    const isSubscriber = UserService.isUserSubscriber();

    if (isSubscriber) {
      setTimeout(
        () => window.location.assign(`${environment.RECURLY_BILLING_PAGE}${hostedLoginToken}`),
        100
      );
    } else {
      batch(() => {
        dispatch(setActiveSubscriptionPlan(planType));
        dispatch(push('/subscription'));
      });
    }
  };
  const renderPricingCard = (pricingTariff: ITariffPlan): ReactElement => {
    const {
      planName,
      priceTitle,
      periodTitle,
      priceSubtitle,
      periodSubtitle,
      CTA,
      benefits,
      isPromotionalPlan,
    } = pricingTariff;
    const planType = getPlanType(planName);

    return (
      <div className={classNames(styles.card, { [styles.promotion]: isPromotionalPlan })}
           key={planName}>
        {isPromotionalPlan &&
          <div className={styles.cardHeader}>
            <span>&#128081;</span>Save 41%
          </div>
        }
        <div className={styles.cardContainer}>
          <div>
            <h3 className={styles.pricePlan}>{planName}</h3>
            <p className={styles.pricePeriod}>
              <span className={styles.priceTitle}>{`${priceTitle}`}</span>
              {` /${periodTitle}`}
            </p>
            <p className={styles.priceSubtitle}>
              <span>{`${priceSubtitle} `}</span>
              {` at ${periodSubtitle}`}
            </p>
            <ul className={styles.benefitsList}>
              {benefits.map((benefit) => (
                <li className={styles.benefitsItem} key={benefit.id}
                    dangerouslySetInnerHTML={{ __html: benefit.text }} />
              ))}
            </ul>
          </div>
          <button
            type="button"
            onClick={() => onSubscriptionButtonClick(planType)}
            className={styles.cardButton}
          >
            {CTA.label}
          </button>
        </div>
      </div>
    );
  };
  const renderModuleContent = () => {
    return (
      <>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.cardsContainer}>
          {tariffPlans.map((c: ITariffPlan) => (
            renderPricingCard(c)
          ))}
        </div>
        <Carousel
          slides={tariffPlans}
          getSlideContent={renderPricingCard}
          slideClassName={styles.pricingTariffSlide}
          paginationClassName={styles.pricingTariffPagination}
        />
        <p className={styles.note}>{subTitle}</p>
        <div className={styles.subscriptionLinks}>
          <NavLink to="/arkadium-player-agreement.pdf" target="_blank" rel="noopener">
            <I18nText keyName="PLAYER_AGREEMENT" />
          </NavLink>{','}&nbsp;
          <NavLink to="/arkadium-plus-agreement.pdf" target="_blank" rel="noopener">
            <I18nText keyName="PLUS_SUBSCRIPTION_AGREEMENT" />
          </NavLink>&nbsp;
          {t('SELECT_SUBSCRIPTION.AND')}&nbsp;
          <NavLink to={UrlService.createURL('privacy-policy')} target="_blank" rel="noopener">
            <I18nText keyName="FOOTER_PRIVACY_POLICY" />
          </NavLink>
        </div>
      </>
    );
  }
  const transformData = (c: ITariffsPlanComponent): ITariffPlan[] => {
    const result = [];

    c.tariffPlans?.forEach((tariffPlan: ITariffPlan) => {
      result.push({
        planName: tariffPlan.planName.toLowerCase(),
        priceTitle: tariffPlan.priceTitle,
        periodTitle: tariffPlan.periodTitle,
        priceSubtitle: tariffPlan.priceSubtitle,
        periodSubtitle: tariffPlan.periodSubtitle,
        benefits: tariffPlan.benefits,
        CTA: tariffPlan.CTA,
        note: c.note,
        isPromotionalPlan: tariffPlan.isPromotionalPlan
      });
    });

      return result;
  };
  const tariffPlans = transformData(PricingTariffsModule);
  const getPlanType = (planName: string): string => {
    const isTrialAvailable = checkTrialPeriodAvailability(userExpiredSubscriptions);
    const annualPlanName = RecurlyPlans(plusFeatureFlag)[PaymentType.subscription][!isTrialAvailable ?
      SubscriptionPlans.ANNUAL :
      SubscriptionPlans.ANNUAL_TRIAL].plan;
    const monthlyPlanName = RecurlyPlans(plusFeatureFlag)[PaymentType.subscription][!isTrialAvailable ?
      SubscriptionPlans.MONTHLY :
      SubscriptionPlans.MONTHLY_TRIAL].plan;
    const code = planName === SUBSCRIPTION_PLAN_NAME.ANNUAL ? annualPlanName : monthlyPlanName;
    const plan = getPlanByCode(code, plans);

    return getPlanNameByCode(plan?.code);
  };

  return (
    <>
      <MediaResponsive greaterThanOrEqual="ARK_SMALL_DESKTOP" style={{ width: '100%' }}>
        <div
          className={styles.content}
          style={getBackground(background, backgroundFallback)}
          id="PricingTariffsModule2025"
        >
          {renderModuleContent()}
        </div>
      </MediaResponsive>
      <MediaResponsive lessThan="ARK_SMALL_DESKTOP" style={{ width: '100%' }}>
        <div
          className={styles.content}
          style={getBackground(backgroundMobile, backgroundFallback)}
          id="PricingTariffsModule2025"
        >
          {renderModuleContent()}
        </div>
      </MediaResponsive>
    </>
  );
});

export default PricingTariffsModule2025;
