import React from 'react';

import { Helmet, HelmetProps } from 'react-helmet-async';
import { useSelector } from 'react-redux';

import { environment } from '../config/environment';
import { Page } from '../models/Strapi';
import ModuleBuilder from '../organisms/StrapiModules/ModuleBuilder/ModuleBuilder';
import { UrlService } from '../services/UrlService';

type StrapiGeneratedProps = {
  page: Page;
};

const SOCIAL_META_MAP = {
  Facebook: 'og',
  Twitter: 'twitter'
};
const StrapiGeneratedPage = React.memo(({ page }: StrapiGeneratedProps) => {
  const { page_sections, seo } = page;
  const routerFromRedux = useSelector(({ router }) => router);
  const url = UrlService.buildAbsoluteUrl(routerFromRedux);
  const helmetProps: HelmetProps = {
    title: seo.metaTitle,
    meta: [{ name: 'description', content: seo.metaDescription }],
    link: [{ rel: 'canonical', href: seo.canonicalURL || `${environment.SITE_BASE_URL}${url}` }]
  };

  if (seo.keywords) {
    helmetProps.meta.push({
      name: 'keywords',
      content: seo.keywords
    });
  }

  if (seo.metaRobots) {
    helmetProps.meta.push({
      name: 'robots',
      content: seo.metaRobots
    });
  }

  if (seo.metaViewport) {
    helmetProps.meta.push({
      name: 'viewport',
      content: seo.metaViewport
    });
  }

  if (seo.metaSocial?.length > 0) {
    for (const social of seo.metaSocial) {
      const name = SOCIAL_META_MAP[social.socialNetwork];

      helmetProps.meta.push({
        name: `${name}:title`,
        content: social.title
      });
      helmetProps.meta.push({
        name: `${name}:description`,
        content: social.description
      });

      if (social.image) {
        helmetProps.meta.push({
          name: `${name}:image`,
          content: UrlService.createStrapiMediaUrl(social.image.url)
        });
      }
    }
  }

  return (
    <>
      <Helmet {...helmetProps}>
        {seo.structuredData && <script type="application/ld+json">{JSON.stringify(seo.structuredData)}</script>}
      </Helmet>
      {page_sections.map((section) => {
        return <ModuleBuilder section={section} key={section.name} />;
      })}
      <div dangerouslySetInnerHTML={{ __html: page.customContent }} />
    </>
  );
});

export default StrapiGeneratedPage;
